export default {
  container: {
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  input: {
    display: 'none',
  },
};
