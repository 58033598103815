import { React, useCallback, useState } from 'react';
import { Chip, Tabs, Tab, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  ArrayField,
  Button,
  BooleanField,
  ChipField,
  SingleFieldList,
  TextField,
  SearchInput,
  List,
  DatagridConfigurable,
  useListContext,
  TopToolbar,
  SelectColumnsButton,
  ExportButton,
  NumberField,
  FunctionField,
} from 'react-admin';
import { FeatureFlag, Loading } from 'components/shared';
import { APP_API_URI } from 'lib/constants';
import FacilityDataList from '../../sharedComponents/FacilityDataList';
import { useStateCountData } from './IngredientsQueries';

const TabbedDatagrid = ({ teamView }) => {
  const { data: stateCounts } = useStateCountData();
  const { filterValues, isFetching, setFilters, displayedFilters } = useListContext();
  const tabs = stateCounts // do not render tabs if stateCounts API call isn't loaded yet
    ? [
        { id: 'all', name: 'all', count: stateCounts.all },
        { id: 'active', name: 'active', count: stateCounts.active },
        { id: 'depleting', name: 'depleting', count: stateCounts.depleting },
        { id: 'legacy', name: 'legacy', count: stateCounts.legacy },
        { id: 'wip', name: 'wip', count: stateCounts.wip },
      ]
    : [];

  const handleChange = useCallback(
    (event, value) => {
      return setFilters && setFilters({ ...filterValues, state: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  const handleRowClick = (id, resource) => {
    window.open(`${APP_API_URI}/admin/${resource}/${id}`, '_blank');
  };

  const getStatusColor = (status) => {
    const statusColors = {
      Complete: 'primary',
      'R&D Ready': 'teal',
    };
    return statusColors[status] || 'default';
  };

  const getOmitList = () => {
    switch (teamView) {
      case 'culinary':
        return [
          'opsCategory',
          'standardUnit',
          'receivingPortioning',
          'unitsPerCase',
          'largeIngredient',
          'pickable',
          'lowTolerance',
          'highTolerance',
          'yieldsByFacility',
          'overOrderByFacility',
          'freightCostByFacility',
          'costPerCaseByFacility',
          'productDateType',
          'state',
          'fsqaStatus',
          'supplyChainStatus',
          'culinaryStatus',
        ];
      case 'fsqa':
        return [
          'displayName',
          'ingredientClassification',
          'usageYield',
          'opsCategory',
          'standardUnit',
          'receivingPortioning',
          'unitsPerCase',
          'largeIngredient',
          'pickable',
          'lowTolerance',
          'highTolerance',
          'yieldsByFacility',
          'overOrderByFacility',
          'freightCostByFacility',
          'costPerCaseByFacility',
          'state',
          'fsqaStatus',
          'supplyChainStatus',
          'culinaryStatus',
        ];
      case 'supplyChain':
        return [
          'displayName',
          'ingredientClassification',
          'usageYield',
          'minimumStorageTemperature',
          'maximumStorageTemperature',
          'pantryCategory',
          'subIngredientsList',
          'allergens',
          'productDateType',
          'state',
          'fsqaStatus',
          'supplyChainStatus',
          'culinaryStatus',
        ];
      default:
        return [
          'displayName',
          'ingredientClassification',
          'usageYield',
          'opsCategory',
          'standardUnit',
          'receivingPortioning',
          'unitsPerCase',
          'largeIngredient',
          'pickable',
          'lowTolerance',
          'highTolerance',
          'yieldsByFacility',
          'overOrderByFacility',
          'freightCostByFacility',
          'costPerCaseByFacility',
          'minimumStorageTemperature',
          'maximumStorageTemperature',
          'pantryCategory',
          'subIngredientsList',
          'allergens',
          'productDateType',
          'state',
        ];
    }
  };

  return (
    <>
      <Tabs value={filterValues.state || 'all'} onChange={handleChange}>
        {tabs.map((state) => (
          <Tab key={state.id} label={`${state.name} (${state.count})`} value={state.id} />
        ))}
      </Tabs>
      <Divider />
      {isFetching ? (
        <Loading className="loadingSpinner" />
      ) : (
        <DatagridConfigurable
          preferenceKey={teamView}
          omit={getOmitList()}
          rowClick={handleRowClick}
        >
          <TextField source="id" label="ID" />
          <TextField source="name" label="Name" />
          <TextField source="displayName" label="Display Name" />
          <TextField source="brandName" label="Brand Name" />
          <TextField source="state" label="State" />
          <TextField
            source="ingredientClassification"
            label="Ingredient Classification"
            sortable={false}
          />
          <TextField source="pantryCategory" label="Pantry Category" sortable={false} />
          <TextField source="opsCategory" label="Ops Category" />
          <TextField source="mealKitEligible" label="Meal Kit Eligibility" />
          <TextField source="rthEligible" label="RTH Eligibility" />
          <ArrayField source="allergens" label="Allergens" sortable={false}>
            <SingleFieldList
              direction="row"
              empty={<p>No allergens to display</p>}
              linkType={false}
            >
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="subIngredientsList" label="Sub Ingredients" sortable={false} />
          <TextField source="standardUnit" label="Standard Unit" />
          <NumberField source="unitsPerCase" label="Units Per Case" />
          <TextField source="highTolerance" label="High Tolerance" />
          <TextField source="lowTolerance" label="Low Tolerance" />
          <NumberField source="usageYield" label="Usage Yield" />
          <TextField source="receivingPortioning" label="Receiving Portioning" />
          <FacilityDataList
            source="yieldsByFacility"
            label="Yields By Facility"
            dataKey="yield"
            sortable={false}
          />
          <FacilityDataList
            source="overOrderByFacility"
            label="Over Order By Facility"
            dataKey="over_order"
            sortable={false}
          />
          <FacilityDataList
            source="freightCostByFacility"
            label="Freight Cost By Facility"
            dataKey="freight_cost"
            sortable={false}
          />
          <FacilityDataList
            source="costPerCaseByFacility"
            label="Cost Per Case By Facility"
            dataKey="cost_per_case"
            sortable={false}
          />
          <NumberField source="maximumStorageTemperature" label="Max Storage Temperature" />
          <NumberField source="minimumStorageTemperature" label="Min Storage Temperature" />
          <BooleanField source="pickable" label="Pickable" />
          <BooleanField source="largeIngredient" label="Large Ingredient" />
          <TextField source="productDateType" label="Product Date Type" />
          <FunctionField
            label="Culinary"
            source="culinaryStatus"
            render={(record) => (
              <Chip color={getStatusColor(record.culinaryStatus)} label={record.culinaryStatus} />
            )}
          />
          <FunctionField
            label="Supply Chain"
            source="supplyChainStatus"
            render={(record) => (
              <Chip
                color={getStatusColor(record.supplyChainStatus)}
                label={record.supplyChainStatus}
              />
            )}
          />
          <FunctionField
            label="FSQA"
            source="fsqaStatus"
            render={(record) => (
              <Chip color={getStatusColor(record.fsqaStatus)} label={record.fsqaStatus} />
            )}
          />
        </DatagridConfigurable>
      )}
    </>
  );
};

const IngredientPantry = () => {
  const [teamView, setTeamView] = useState('default');
  const views = [
    { id: 'default', label: 'Default View' },
    { id: 'culinary', label: 'Culinary View' },
    { id: 'supplyChain', label: 'Supply Chain View' },
    { id: 'fsqa', label: 'FSQA View' },
  ];
  const ListActions = () => {
    return (
      <TopToolbar>
        {views.map(({ id, label }) => (
          <Button
            key={id}
            label={label}
            onClick={() => setTeamView(id)}
            className={teamView === id ? 'activeView' : 'inactiveView'}
          />
        ))}
        <SelectColumnsButton preferenceKey={teamView} />
        <ExportButton />
        <Button
          label="New Ingredient"
          startIcon={<AddIcon />}
          onClick={() => window.open(`${APP_API_URI}/admin/ingredients/new`, '_blank')}
          className="newIngredientButton"
        />
      </TopToolbar>
    );
  };
  const basicIngredientFilters = [
    <SearchInput key="search" source="q" placeholder="Search" alwaysOn />,
  ];

  return (
    <FeatureFlag flag="show_basic_ingredients_hub">
      <List
        actions={<ListActions />}
        filters={basicIngredientFilters}
        sort={{ field: 'id', order: 'DESC' }}
        sx={(theme) => ({ ...theme.components.IngredientPantryDatagrid })}
      >
        <TabbedDatagrid teamView={teamView} />
      </List>
    </FeatureFlag>
  );
};

export default IngredientPantry;
