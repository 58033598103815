import { defaultTheme } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from '@mui/utils';
import { AVATAR_TEAL, HC_GREEN, MEDIUM_GREY, BACKGROUND_GREY, ERROR, WHITE } from 'lib/constants';

export const hubAppTheme = deepmerge(defaultTheme, {
  palette: {
    primary: {
      main: HC_GREEN,
    },
    secondary: {
      main: HC_GREEN,
    },
    background: {
      paper: BACKGROUND_GREY,
    },
    error: {
      main: ERROR,
    },
    teal: {
      main: AVATAR_TEAL,
      contrastText: WHITE,
    },
  },
  components: {
    AppBar: {
      flexGrow: 1,
      py: 1,
      '.logo': {
        height: '48px',
        pr: 2,
      },
    },
    IngredientPantryDatagrid: {
      '& .RaDatagrid-headerCell': { textAlign: 'left' },
      '& .RaDatagrid-rowCell': { textAlign: 'left' },
      '.loadingSpinner': { paddingBottom: '200px' },
      '.newIngredientButton': {
        backgroundColor: HC_GREEN,
        color: WHITE,
        fontSize: '16px',
        ':hover': {
          backgroundColor: WHITE,
          color: HC_GREEN,
        },
      },
      '.activeView': {
        color: HC_GREEN,
        borderBottom: 1,
        borderColor: HC_GREEN,
        borderRadius: 'inherit',
      },
      '.inactiveView': {
        color: MEDIUM_GREY,
        ':hover': {
          color: HC_GREEN,
        },
      },
      '.primaryText': {
        size: '1rem',
      },
      '.secondaryText': {
        color: MEDIUM_GREY,
      },
    },
  },
});
