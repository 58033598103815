import { DEFAULT_SPACING, LIGHT_GREY, THICK_SPACING, MEDIUM_GREY } from 'lib/constants';

export default {
  root: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    height: '100%',
    marginTop: THICK_SPACING,
    marginBottom: THICK_SPACING,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: THICK_SPACING,
    paddingBottom: THICK_SPACING,
    paddingLeft: DEFAULT_SPACING,
    paddingRight: DEFAULT_SPACING,
    width: '640px',
  },
  header: {
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '400',
    margin: 0,
  },
  radioGroupContainer: {
    alignSelf: 'flex-start',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  visible: {
    display: 'normal',
    visibility: 'visible',
  },
  divider: {
    backgroundColor: LIGHT_GREY,
    height: '1px',
    border: 'none',
    width: '100%',
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0',
  },
  addAndUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  addAndUploadMargin: {
    marginTop: '12px',
  },
  fileRequirementText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    opacity: '0.6',
    marginBottom: '16px',
    display: 'block',
  },
  fileUploadedText: {
    color: MEDIUM_GREY,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginBottom: '16px',
    display: 'block',
  },
};
