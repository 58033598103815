import React, { useState, useEffect, useContext } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const MealSelector = ({
  classes,
  isRewardSection = false,
  meals = [],
  selectedMeals = [],
  selectedMealsCallback = () => {},
  visible = false,
}) => {
  const { formState } = useContext(BehavioralDiscountFormContext);
  const [mealSelectorOpen, setMealSelectorOpen] = useState(false);
  const [searchMeals, setSearchMeals] = useState(meals);
  const [mealSortOrder, setMealSortOrder] = useState('asc');
  const [productSortOrder, setProductSortOrder] = useState('asc');
  const handleClose = () => [setMealSelectorOpen(false), setSearchMeals(meals)];

  const handleSelectedMealsUpdate = (selected) => {
    selectedMealsCallback(selected);
  };

  const onSelectAllClick = () => {
    const mealsToSelect = searchMeals.length === 0 ? meals : searchMeals;
    if (selectedMeals.length < mealsToSelect.length) {
      handleSelectedMealsUpdate(mealsToSelect.map((m) => m.id));
    } else {
      handleSelectedMealsUpdate([]);
    }
  };

  const handleClick = (mealId) => {
    const selectedIndex = selectedMeals.indexOf(mealId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMeals, mealId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMeals.slice(1));
    } else if (selectedIndex === selectedMeals.length - 1) {
      newSelected = newSelected.concat(selectedMeals.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMeals.slice(0, selectedIndex),
        selectedMeals.slice(selectedIndex + 1)
      );
    }

    handleSelectedMealsUpdate(newSelected);
  };

  const findMealById = (mealId) => meals.find((m) => m.id === mealId);

  const requestSearch = (searchTerm) => {
    setSearchMeals(
      meals.filter(
        (meal) =>
          meal.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          meal.type.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const sortMeals = (arr, orderBy, columnName) => {
    switch (orderBy) {
      case 'asc':
      default:
        return arr.sort((a, b) => a[columnName].localeCompare(b[columnName]));
      case 'desc':
        return arr.sort((a, b) => a[columnName].localeCompare(b[columnName])).reverse();
    }
  };

  const handleSortRequest = (columnName, sortOrder) => {
    setSearchMeals(sortMeals(meals, sortOrder, columnName));
    if (columnName === 'title') {
      setMealSortOrder(mealSortOrder === 'asc' ? 'desc' : 'asc');
    } else if (columnName === 'type') {
      setProductSortOrder(productSortOrder === 'asc' ? 'desc' : 'asc');
    }
  };

  useEffect(() => {
    setSearchMeals(meals);
  }, [meals]);

  const displayForRewardsSection = isRewardSection
    ? formState.discountDuration !== 'recurring'
    : true;

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={
        visible
          ? [classes.visible, classes.container, isRewardSection && classes.rewardSectionContainer]
          : classes.hidden
      }
    >
      {displayForRewardsSection && (
        <h6 className={classes.header}>
          Eligible Products <span className={classes.headerCount}>({selectedMeals.length})</span>
        </h6>
      )}
      {selectedMeals.length !== 0 &&
        meals !== null &&
        meals.length !== 0 &&
        displayForRewardsSection && (
          <TableContainer>
            <Table>
              <TableBody>
                {selectedMeals.map((selected) => (
                  <TableRow key={selected} className={classes.mealTableRow}>
                    <TableCell className={classes.mealTableCell}>
                      <div className={classes.mealTableTitle}>{findMealById(selected).title}</div>
                      <span>{findMealById(selected).type}</span>
                    </TableCell>
                    <TableCell align="right" className={classes.mealTableCell}>
                      <IconButton onClick={() => handleClick(selected)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <Container
        className={isRewardSection ? classes.rewardSection : classes.mealSelectorButtonContainer}
        padding="none"
        disableGutters
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={
            meals.length === 0 || (isRewardSection && formState.discountDuration === 'recurring')
          }
          onClick={() => setMealSelectorOpen(true)}
        >
          <AddIcon /> Add Meals
        </Button>
      </Container>
      <Drawer
        anchor="right"
        open={mealSelectorOpen}
        classes={{ paper: classes.drawerPaper }}
        PaperProps={{ style: { width: '35%' } }}
      >
        <Container key="bd_meal_selector">
          <Grid container direction="column" justifyContent="flex-start" spacing={0} height="100vh">
            <Grid item xs={12} maxHeight="12vh">
              <Box className={classes.titleBar}>
                Select Meals
                <IconButton onClick={() => handleClose()} className={classes.closeBtn} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search..."
                type="search"
                onInput={(e) => requestSearch(e.target.value)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} maxHeight="81vh" overflow="hidden" sx={{ overflowY: 'auto' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedMeals.length > 0 && selectedMeals.length < meals.length
                          }
                          checked={meals.length > 0 && selectedMeals.length === meals.length}
                          onChange={onSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all products',
                          }}
                        />
                      </TableCell>
                      <TableCell
                        key="mealSlug"
                        align="left"
                        padding="none"
                        onClick={() => handleSortRequest('title', mealSortOrder)}
                      >
                        <TableSortLabel direction={mealSortOrder}>Meal</TableSortLabel>
                      </TableCell>
                      <TableCell
                        key="productType"
                        align="left"
                        padding="normal"
                        sortDirection={false}
                        onClick={() => handleSortRequest('type', productSortOrder)}
                      >
                        <TableSortLabel direction={productSortOrder}>Product Type</TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchMeals.map((meal) => (
                      <TableRow
                        key={meal.id}
                        hover
                        onClick={() => handleClick(meal.id)}
                        role="checkbox"
                        aria-checked={selectedMeals.includes(meal.id)}
                        tabIndex={-1}
                        selected={selectedMeals.includes(meal.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedMeals.includes(meal.id)}
                            inputProps={{
                              'aria-labelledby': `title-${meal.id}`,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={`title-${meal.id}`}
                          scope="row"
                          padding="none"
                        >
                          {meal.title}
                        </TableCell>
                        <TableCell>{meal.type}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              width="100%"
              minHeight="50px"
              maxHeight="7vh"
            >
              <Button
                onClick={() => handleClose()}
                variant="contained"
                disabled={selectedMeals.length <= 0}
                sx={{ width: '100%', height: '40px', marginTop: '8px' }}
              >
                Add Meals
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </Container>
  );
};

export default MealSelector;
