import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

import MoneyInput from '../Common/MoneyInput';
import PercentFormatter from './PercentFormatter/PercentFormatter';
import MealSelector from '../Common/MealSelector';
import ProductTypeSelector from './ProductTypeSelector';
import CsvSelector from './CsvSelector';

const RewardSection = ({ classes, filteredMeals = [] }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const handleRewardTypeChange = (rewardType) => {
    actions.setFormState('discountTarget', rewardType);
    if (rewardType === 'shipping') {
      actions.setFormState('discountType', 'percent');
      actions.setFormState('valuePercent', 100);
    } else if (rewardType === 'pickable_item') {
      actions.setFormState('valueCents', 0);
      actions.setFormValidation('errors', '');
    } else {
      actions.setFormState('valueCents', 0);
      actions.setFormState('valuePercent', 0);
    }
  };

  const handleDiscountTypeChange = (newType) => {
    actions.setFormState('discountType', newType);
    if (newType === 'cents') {
      actions.setFormState('valuePercent', 0);
    } else if (newType === 'percent') {
      actions.setFormState('valueCents', 0);
    }
  };

  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Reward</h6>
      <div className={classes.radioGroupContainer}>
        <FormControl>
          <FormLabel id="trigger-type-radio-buttons-group-label">Reward Type</FormLabel>
          <RadioGroup
            aria-labelledby="trigger-type-radio-buttons-group-label"
            value={formState.discountTarget}
            onChange={(e) => handleRewardTypeChange(e.target.value)}
          >
            <FormControlLabel value="basket" control={<Radio />} label="Basket Discount" />
            <FormControlLabel
              value="meal"
              control={<Radio />}
              label="Meal Discount"
              className={
                formState.lastMenuEndsAt && formState.lastMenuEndsAt !== formState.menuEndsAt
                  ? classes.hidden
                  : classes.visible
              }
            />
            <FormControlLabel
              value="product_type"
              control={<Radio />}
              label="Product Type Discount"
            />
            <FormControlLabel value="pickable_item" control={<Radio />} label="Pickable Item" />
            <FormControlLabel value="shipping" control={<Radio />} label="Free Shipping" />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider
        className={
          formState.discountTarget === 'pickable_item' || formState.discountTarget === 'shipping'
            ? classes.hidden
            : classes.divider
        }
      />
      <Container
        className={
          formState.discountTarget === 'meal' ||
          formState.discountTarget === 'product_type' ||
          formState.discountTarget === 'basket'
            ? classes.visible
            : classes.hidden
        }
        disableGutters
      >
        <TextField
          fullWidth
          label="Number of Orderable Units Available for Discount"
          variant="outlined"
          value={formState.maxOrderableUnits}
          onChange={(e) => actions.setFormState('maxOrderableUnits', e.target.value)}
          className={
            formState.discountTarget === 'meal' || formState.discountTarget === 'product_type'
              ? classes.visible
              : classes.hidden
          }
        />
        <Box
          className={[
            classes.addAndUploadContainer,
            formState.discountDuration === 'recurring' && classes.addAndUploadMargin,
          ]}
        >
          <MealSelector
            meals={filteredMeals}
            selectedMeals={formState.rewardMenuSelectionIds}
            selectedMealsCallback={(selectedRewardMeals) =>
              actions.setFormState('rewardMenuSelectionIds', selectedRewardMeals)
            }
            visible={formState.discountTarget === 'meal'}
            isRewardSection
          />
          <CsvSelector
            visible={formState.discountTarget === 'meal'}
            setUploadedCsv={(csv) => actions.setFormState('rewardMealsCsv', csv)}
          />
        </Box>
        {formState.rewardMealsCsv && (
          <Typography className={classes.fileUploadedText}>1 file uploaded</Typography>
        )}
        {formState.discountTarget === 'meal' && (
          <Typography className={classes.fileRequirementText}>
            Columns &quot;menu_id&quot; and &quot;meal_id&quot; required in csv upload.
          </Typography>
        )}
        <ProductTypeSelector visible={formState.discountTarget === 'product_type'} />
        <Grid container spacing={2}>
          <Grid item sm={10} xs={8}>
            <div className={formState.discountType === 'cents' ? classes.visible : classes.hidden}>
              <MoneyInput
                label="Discount Amount"
                fullWidth
                setFormError={(value) => actions.setFormValidation('errors', value)}
                disabled={formState.discountType !== 'cents'}
                focused={formState.valueCents >= 0}
                value={formState.valueCents}
              />
            </div>
            <div
              className={formState.discountType === 'percent' ? classes.visible : classes.hidden}
            >
              <TextField
                fullWidth
                label="Discount Percent"
                variant="outlined"
                value={formState.valuePercent}
                InputProps={{
                  inputComponent: PercentFormatter,
                }}
                onChange={(e) => actions.setFormState('valuePercent', e.target.value)}
              />
            </div>
          </Grid>
          <Grid item sm={2} xs={4}>
            <ToggleButtonGroup
              color="primary"
              value={formState.discountType}
              exclusive
              onChange={(e) => handleDiscountTypeChange(e.target.value)}
              aria-label="Discount Type"
              size="large"
              sx={{ width: 1 }}
            >
              <ToggleButton value="cents" sx={{ width: 1 / 2 }}>
                $
              </ToggleButton>
              <ToggleButton value="percent" sx={{ width: 1 / 2 }}>
                %
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default RewardSection;
