import { HC_GREEN, LIGHT_GREEN, MEDIUM_SPACING } from 'lib/constants';

export default {
  header: {
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '400',
    margin: '0',
  },
  headerCount: {
    fontWeight: '700',
  },
  drawerPaper: {
    maxWidth: '600px',
    minWidth: '300px',
  },
  mealTableRow: {
    padding: 'none',
  },
  mealTableCell: {
    fontSize: '.9rem',
    padding: '8px 16px',
  },
  mealTableTitle: {
    fontWeight: '500',
  },
  mealSelectorButtonContainer: {
    marginTop: '6px',
  },
  rewardSection: {
    marginTop: '6px',
    marginBottom: MEDIUM_SPACING,
  },
  titleBar: {
    position: 'relative',
    fontSize: '20px',
    padding: '16px',
  },
  closeBtn: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  toggleButton: {
    '&.Mui-selected': {
      color: HC_GREEN,
      backgroundColor: LIGHT_GREEN,
    },
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  visible: {
    display: 'normal',
    visibility: 'visible',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  rewardSectionContainer: {
    width: 'unset',
    marginLeft: 'unset',
    marginRight: '12px',
  },
};
