import React, { useRef } from 'react';

import { Box, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const CsvSelector = ({ classes, setUploadedCsv, visible }) => {
  const fileInputRef = useRef(null);

  const onCsvUpload = (e) => {
    if (!e.target.files) return;

    const csvFile = e.target.files[0];
    setUploadedCsv(csvFile);
  };

  return (
    <Box className={visible ? classes.container : classes.hidden}>
      <Button variant="outlined" color="primary" onClick={() => fileInputRef.current.click()}>
        <UploadIcon /> Upload CSV
        <input
          ref={fileInputRef}
          className={classes.input}
          type="file"
          accept=".csv"
          onChange={onCsvUpload}
        />
      </Button>
    </Box>
  );
};

export default CsvSelector;
